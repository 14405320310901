(function flavorsSorts() {
	const Portfolio = {
		sort(items) {
			items.show();
			$('#portfolio-content').find('div.portfolio-item').not(items).fadeOut(500);
		},
		showAll(items) {
			items.fadeIn(500);
		},
		doSort() {
			$('a', '#portfolio-sort').on('click', function () {
				const $a = $(this);
				$('a', '#portfolio-sort').removeClass('current');
				$a.addClass('current');
				if (!$a.is('#all')) {
					const items = $('div[data-cat=' + $a.data('cat') + ']', '#portfolio-content');
					Portfolio.sort(items);
				} else {
					Portfolio.showAll($('div.portfolio-item', '#portfolio-content'));
				}
			});
		}
	};

	Portfolio.doSort();
})();
