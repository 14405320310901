(function mapResellers() {
	let map;
	let info_window = null;

	const objId = document.querySelector('#mapResellers');
	const markers = [];

	function getData() {
		return $.ajax({
			url: './build/data/resellers.json',
		});
	};

	function fitBoundsToVisibleMarkers() {
		const bounds = new google.maps.LatLngBounds();

		for (let i = 0; i < markers.length; i++) {
			if(markers[i].getVisible()) {
				bounds.extend(markers[i].getPosition());
			}
		}
		map.fitBounds(bounds);
		
	}

	function clickOnPin() {
		$('.marker-reseller').on('click', function() {
			if ($.browser.mobile) {
				$('html, body').animate({
					scrollTop: $('#mapResellers').offset().top - 16,
				}, 800);
			}
			const [lat, lng] = $(this).data('location').split(', ');
			const center = { lat: lat * 1, lng: lng * 1 };
			// console.log(center);
			map.setZoom(16);
			map.panTo(center);
		});
	}


	function tableShops(data) {
		$('#table').html(tmpl('table-template', {shops: data}));
	}

	const googleAddMarker = (center, map, info) => {
		// Retina support
		let requireImagePrefix = '';
		const devicePixelRatio = (window.devicePixelRatio===undefined?1:window.devicePixelRatio);

		if (devicePixelRatio > 1) {
			requireImagePrefix = '@2x';
		}

		const pinImage = {
			url: `./build/images/reseller-marker${requireImagePrefix}.png`,
			scaledSize: new google.maps.Size(36, 47),
			// anchor: new google.maps.Point(0, 64)
		};

		const marker = new google.maps.Marker({
			position: center,
			map: map,
			icon: pinImage,
			animation: google.maps.Animation.DROP,
			optimized: true
		});

		google.maps.event.addListener(marker, 'click', function() {
			const bounds = new google.maps.LatLngBounds();
			bounds.extend(marker.getPosition());
			map.fitBounds(bounds);
			map.panToBounds(bounds);
			map.setZoom(16);

			if (info_window) {
				info_window.close();
			}

			const content = `
				<div class="infobox">
					<p><b>${info.content.City}</b> — ${info.content.Address}</p>
				</div>
				`
			info_window = new google.maps.InfoWindow({ content: content, maxWidth: 220 });
			info_window.open(map, marker);
		});

		markers.push(marker);
	};

	function initMap() {
		const mapOptions = {
			zoom: 10,
			disableDefaultUI: true,
			// minZoom: 12,
			// maxZoom: 20,
			styles: [
				{
					"featureType": "all",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#7c93a3"
						},
						{
							"lightness": "-10"
						}
					]
				},
				{
					"featureType": "administrative.country",
					"elementType": "geometry",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "administrative.country",
					"elementType": "geometry.stroke",
					"stylers": [
						{
							"color": "#a0a4a5"
						}
					]
				},
				{
					"featureType": "administrative.province",
					"elementType": "geometry.stroke",
					"stylers": [
						{
							"color": "#62838e"
						}
					]
				},
				{
					"featureType": "landscape",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"color": "#dde3e3"
						}
					]
				},
				{
					"featureType": "landscape.man_made",
					"elementType": "geometry.stroke",
					"stylers": [
						{
							"color": "#3f4a51"
						},
						{
							"weight": "0.30"
						}
					]
				},
				{
					"featureType": "poi",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "simplified"
						}
					]
				},
				{
					"featureType": "poi.attraction",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "poi.business",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi.government",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi.park",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "poi.place_of_worship",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi.school",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi.sports_complex",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "all",
					"stylers": [
						{
							"saturation": "-100"
						},
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "geometry.stroke",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"color": "#bbcacf"
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "geometry.stroke",
					"stylers": [
						{
							"lightness": "0"
						},
						{
							"color": "#bbcacf"
						},
						{
							"weight": "0.50"
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "labels",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "labels.text",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "road.highway.controlled_access",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"color": "#ffffff"
						}
					]
				},
				{
					"featureType": "road.highway.controlled_access",
					"elementType": "geometry.stroke",
					"stylers": [
						{
							"color": "#a9b4b8"
						}
					]
				},
				{
					"featureType": "road.arterial",
					"elementType": "labels.icon",
					"stylers": [
						{
							"invert_lightness": true
						},
						{
							"saturation": "-7"
						},
						{
							"lightness": "3"
						},
						{
							"gamma": "1.80"
						},
						{
							"weight": "0.01"
						}
					]
				},
				{
					"featureType": "transit",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"color": "#a3c7df"
						}
					]
				}
			],
			scrollwheel: false,
			scaleControl: false,
			zoomControl: true,
			// disableDoubleClickZoom: true,
			center: new google.maps.LatLng(32.56163037, 54.40429688),
			zoomControlOptions: {
				style: google.maps.ZoomControlStyle.LARGE,
				position: google.maps.ControlPosition.LEFT_TOP
			},
			mapTypeId: google.maps.MapTypeId.ROADMAP,
		};

		map = new google.maps.Map(objId, mapOptions);
	}

	if ($('#mapResellers').length > 0) {
		$.getScript('https://maps.googleapis.com/maps/api/js?language=fa&key=AIzaSyDv4q05lZ1lBiYfQ2X75UTzRjAmWxZbAgk', function() {
			getData().then(data => {
				tableShops(data);
				initMap();
				data.map(shop => {
					googleAddMarker({
						lat: shop.LAT,
						lng: shop.LONG
					}, map, {
						content: shop
					});
				});
				$('.spinner').hide();
				$('#loaded').fadeIn();

				fitBoundsToVisibleMarkers();
				clickOnPin();

				if ($.browser.desktop) {
					const mapBox = new StickySidebar('.sidebar', {
						containerSelector: '#loaded',
						innerWrapperSelector: '.iran',
						topSpacing: 16,
						bottomSpacing: 16
					});
				}
			});
		});

	}
})();
